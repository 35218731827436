<template>
  <div id="content-area">
    <h1>
      Målingsværktøjet er gratis - støttet af
      <img :src="logoUrl" alt="" width="120">
      Foreningen
    </h1>
    <p>I er kun tre trin fra at kunne måle arbejdsfællesskabet og få anbefalinger til indsatser.</p>
    <ol class="list">
      <li>
        Opret profil
      </li>
      <li>
        Del link til spørgeskema med medarbejderne
      </li>
      <li>
        Modtag en rapport med resultater og anbefalinger
      </li>
    </ol>
    <h4>{{message}}</h4>
    <div class="input-container">
      <input type="text" placeholder="email" v-model="user.email">
    </div>
    <div class="input-container">
      <input type="password" placeholder="password" v-model="user.password">
    </div>
    <div>
      <p>Vi opbevarer jeres data sikkert og deler dem ikke med tredjepart</p>
    </div>
    <div class="input-container checkbox">
      <input type="checkbox" id="terms" v-model="termsAcceptet">
      <label for="terms">Jeg har læst <a :href="`${baseUrl}/#/vilkaar`" target="_blank">betingelser</a> for brug</label>
    </div>
    <nav class="horizontal-flex">
      <base-ui-button @clicked="redirect">
        Annullér
      </base-ui-button>
      <base-ui-button @clicked="userRegistration" primary>
        Opret profil
      </base-ui-button>
    </nav>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

import BaseUiButton from '../components/shared/BaseUiButton'
import { errorMessages } from '../sharedFunctions/errorMessages'

export default {
  name: '',
  components: { BaseUiButton },
  data () {
    return {
      user: {
        email: '',
        password: ''
      },
      termsAcceptet: false,
      message: ''
    }
  },
  computed: {
    baseUrl () {
      return window.location.origin
    }
  },
  mounted () {
    this.$emit('update-background', 'rgb(253,252,248)')
  },
  methods: {
    redirect () {
      if (this.$route.query.redirect) {
        this.$router.replace({ path: this.$route.query.redirect })
      } else {
        this.$router.replace({ path: '/' })
      }
    },
    userRegistration () {
      if (this.termsAcceptet) {
        firebase.auth().createUserWithEmailAndPassword(this.user.email, this.user.password)
          .then((res) => {
            res.user
              .updateProfile({
                displayName: this.user.name
              })
              .then(() => {
                this.$store.dispatch('updateUser', res.user)
                this.$router.push('/login')
              })
          })
          .catch((e) => {
            this.message = errorMessages[e.code] || 'Der skete en fejl, prøv igen senere '
          })
      } else {
        this.message = 'Du skal acceptere betingelser for brug for at kunne oprette en profil'
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
#content-area
  justify-content center
  max-width 27rem
</style>
